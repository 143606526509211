<template>
  <div class="policemen_warp">
    <div class="main_top">
      <div style="display: flex">
        <el-select
          v-if="adminid == 1"
          v-model="procuratorateid"
          placeholder="请选择检察院名称"
          style="width: 300px; margin-right: 10px"
        >
          <el-option
            v-for="item in proList"
            :key="item.id"
            :value="item.id"
            :label="item.procuratoratename"
          ></el-option>
        </el-select>
        <el-select
          clearable
          v-model="sexidwa"
          placeholder="请选择性别"
          style="width: 120px; margin-right: 10px"
        >
          <el-option
            v-for="item in allSexy"
            :key="item.id"
            :value="item.id"
            :label="item.gender"
          ></el-option>
        </el-select>
        <el-select
          clearable
          v-model="typeidwa"
          placeholder="请选择用户类型"
          style="width: 200px"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :value="item.id"
            :label="item.typesname"
          ></el-option>
        </el-select>
        <el-input
          clearable
          v-model="selectTxt"
          placeholder="请输入姓名、手机号搜索"
          style="width: 250px; margin-left: 10px; margin-right: 10px"
        ></el-input>
        <el-button
          slot="reference"
          @click="departSelect"
          class="style_button style_button_Sel"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div style="display: flex">
        <div>
          <el-button class="style_button" type="text" @click="handleAdd()"
            >添加</el-button
          >
          <el-button
            class="style_button"
            type="text"
            @click="handleExport()"
            >导出</el-button
          >
          <el-dialog
            :visible.sync="dialogFormVisible11"
            @close="closeDialog11"
            :close-on-click-modal="false"
          >
            <div slot="title" class="dialog_title">
              <img src="../../assets/zhcpIcon/add_icon.png" alt="" />
              <span>{{ titleName }}</span>
            </div>
            <el-form :model="form2">
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户姓名
                </div>
                <el-input
                  v-model="form2.username"
                  autocomplete="off"
                  :disabled="showdetail"
                ></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户性别
                </div>
                <el-select
                  v-model="form2.sex"
                  placeholder="请选择"
                  :disabled="showdetail"
                >
                  <el-option
                    v-for="item in allSexy"
                    :key="item.id"
                    :value="item.id"
                    :label="item.gender"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label"><span>*</span>手机号</div>
                <el-input
                  v-model="form2.phone"
                  autocomplete="off"
                  :disabled="showdetail"
                ></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>所属检察院
                </div>
                <el-select
                  v-if="adminid == 1"
                  v-model="form2.procuratorateid"
                  placeholder="请选择检察院"
                  :disabled="showdetail || adminid != 1"
                  @change="changepro"
                >
                  <el-option
                    v-for="item in proList1"
                    :key="item.id"
                    :value="item.id"
                    :label="item.procuratoratename"
                  ></el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="procuratoratename1"
                  autocomplete="off"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label"><span>*</span>备注</div>
                <el-input v-model="form.remark" autocomplete="off" :disabled="showdetail"></el-input>
              </el-form-item> -->
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户类型
                </div>
                <el-select
                  @change="slectone"
                  v-model="form2.typeid"
                  placeholder="请选择"
                  :disabled="showdetail"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.typesname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label-width="formLabelWidth"
                v-if="form2.typeid == 2 || form2.typeid == 8 || form2.typeid == 9"
              >
                <div class="form_label" slot="label">
                  <span>*</span>部门名称
                </div>
                <el-select
                  v-model="form2.deptid"
                  placeholder="请选择部门"
                  :disabled="showdetail"
                >
                  <el-option
                    v-for="item in depList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.depname"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible11 = false">取 消</el-button>
              <el-button type="primary" @click="handleClick()">确 定</el-button>
            </div>
          </el-dialog>

          <el-dialog
            :visible.sync="dialogFormVisible"
            @close="closeDialog"
            :close-on-click-modal="false"
          >
            <div slot="title" class="dialog_title">
              <img src="../../assets/zhcpIcon/add_icon.png" alt="" />
              <span>{{ titleName }}</span>
            </div>
            <el-form :model="form">
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户姓名
                </div>
                <el-input
                  v-model="form.username"
                  autocomplete="off"
                  :disabled="showdetail"
                ></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户性别
                </div>
                <el-select
                  v-model="form.sex"
                  placeholder="请选择"
                  :disabled="showdetail"
                >
                  <el-option
                    v-for="item in allSexy"
                    :key="item.id"
                    :value="item.id"
                    :label="item.gender"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label"><span>*</span>手机号</div>
                <el-input
                  v-model="form.phone"
                  autocomplete="off"
                  :disabled="showdetail"
                ></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>所属检察院
                </div>
                <el-select
                  v-if="adminid == 1"
                  v-model="form.procuratorateid"
                  placeholder="请选择检察院"
                  :disabled="showdetail || adminid != 1"
                  @change="changepro"
                >
                  <el-option
                    v-for="item in proList1"
                    :key="item.id"
                    :value="item.id"
                    :label="item.procuratoratename"
                  ></el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="procuratoratename1"
                  autocomplete="off"
                  :disabled="true"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label"><span>*</span>备注</div>
                <el-input v-model="form.remark" autocomplete="off" :disabled="showdetail"></el-input>
              </el-form-item> -->
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>用户类型
                </div>
                <el-select
                  @change="slectone"
                  v-model="form.typeid"
                  placeholder="请选择"
                  :disabled="showdetail"
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.typesname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label-width="formLabelWidth"
                v-if="form.typeid == 2 || form.typeid == 8 || form.typeid == 9"
              >
                <div class="form_label" slot="label">
                  <span>*</span>部门名称
                </div>
                <el-select
                  v-model="form.deptid"
                  placeholder="请选择部门"
                  :disabled="showdetail"
                >
                  <el-option
                    v-for="item in depList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.depname"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleClick()">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      tooltip-effect="dark"
    >
      <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
      <el-table-column label="序号" width="50">
        <template slot-scope="scope">
          <span>{{ (page - 1) * pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <!-- <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template> -->
      <!-- </el-table-column> -->
      <el-table-column label="检察院名称">
        <template slot-scope="scope">
          <p>{{ scope.row.procuratorate.procuratoratename }}</p>
        </template>
      </el-table-column>
      <el-table-column label="姓名">
        <template slot-scope="scope">
          <p>{{ scope.row.username }}</p>
        </template>
      </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <p>{{ scope.row.sex == 0 ? "女" : "男" }}</p>
        </template>
      </el-table-column>
      <el-table-column label="手机号码">
        <template slot-scope="scope">
          <p>{{ scope.row.phone }}</p>
        </template>
      </el-table-column>
      <el-table-column label="用户类型" width="200">
        <template slot-scope="scope">
          <p v-if="scope.row.types.id == 1">院领导</p>
          <p v-if="scope.row.types.id == 2">检察官</p>
          <p v-if="scope.row.types.id == 3">听证管理员</p>
          <p v-if="scope.row.types.id == 7">普通用户</p>
          <p v-if="scope.row.types.id == 8">检察官助理</p>
          <p v-if="scope.row.types.id == 9">书记员</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" class="operate" width="400">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleDetail(scope.row.id)"
            class="editColor"
            >详情</el-button
          >
          <el-button
            size="mini"
            @click="handleEdit(scope.row.id)"
            class="editColor"
            >编辑</el-button
          >
          <el-button
            size="mini"
            @click="handleStop(scope.row.id, scope.row.state)"
            :style="{
              background: scope.row.state == 1 ? '#2FC25B' : '#FF4E4E',
            }"
            >{{ scope.row.state == 1 ? "启动" : "停用" }}</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleRemove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import {
  getAllUserInfo,
  getUserInfoById,
  updateUserInfo,
  deleteUserInfo,
  addUserInfo,
} from "@/api/user";
import { getProInfoList, getDepartmentByPage } from "@/api/system";
import { downloadFile } from "@/util/formtime";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      adminid: JSON.parse(window.localStorage.getItem("tltz_userinfo")).id,
      options: [],
      perList: [],
      value: [],
      list: [],
      loading: false,
      states: [],
      dialogFormVisible: false,
      dialogFormVisible11: false,
      form: {},
      form2: {
        username: "",
        sex: "",
        phone: "",
        typeid: "",
        deptid:'',
        procuratorateid: "",
      },
      allSexy: [
        { id: 1, gender: "男" },
        { id: 0, gender: "女" },
      ],
      procuratoratename1: JSON.parse(
        window.localStorage.getItem("tltz_userinfo")
      ).procuratorate.procuratoratename,
      typeidwa: "",
      sexidwa: "",
      typeList: [
        {
          id: 1,
          typesname: "院领导",
        },
        {
          id: 2,
          typesname: "检察官",
        },
        {
          id: 3,
          typesname: "听证管理员",
        },
        {
          id: 7,
          typesname: "普通用户",
        },
        {
          id: 8,
          typesname: "检察官助理",
        },
        {
          id: 9,
          typesname: "书记员",
        },
      ],
      formLabelWidth: "95px",
      tableData: [], //干警列表
      total: 0, //总条数
      page: 1, //页数
      pageSize: 10, //每页限制
      currentPage: 1, //当前页数
      multipleSelection: [],
      allSpecialty: [],
      proList: [],
      proList1: [],
      depList: [],
      getInfo: [],
      selectTxt: "", //搜索当前项
      titleName: "",
      procuratorateid: null,
      selecttype: 0,
      disabled: false,
      typelist: [],
      showdetail: false,
    };
  },
  mounted() {
        this.typeid = JSON.parse(
      window.localStorage.getItem("tltz_userinfo")
    ).types.id;
    if (this.typeid == 6) {
      this.procuratorateid = -1;
    } else {
      this.procuratorateid = JSON.parse(
        window.localStorage.getItem("tltz_userinfo")
      ).procuratorate.id;
      this.disabled = true;
    }
    this.getproList2()
    this.list = this.states.map((item) => {
      return { value: `value:${item}`, label: `label:${item}` };
    });
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));

    console.log(JSON.parse(window.localStorage.getItem("tltz_userinfo")));
    this._getAllUserInfo();
    // this._getAllSpecialty();
    this.getproList();
  },
  methods: {
    slectone() {
      console.log("11111111111");
      // this.getdepList();
      var procuratorateid = this.form.typeid
      getDepartmentByPage(1, 999, this.adminid,procuratorateid).then(
        (data) => {
          this.depList = data.data.list;
        }
      );
    },
    changepro(e) {
      console.log(e);
      this.getdepList(e);
    },
    getdepList(e) {
      const procuratorateid = e;
      getDepartmentByPage(1, 999, this.adminid, procuratorateid).then(
        (data) => {
          this.depList = data.data.list;
        }
      );
    },
    //获取列表
    getproList() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        this.proList = data.data.list;
        this.proList.unshift({
          id: -1,
          procuratoratename: "全部",
        });
      });
    },
    //获取列表
    getproList2() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        console.log(data,'data111')
        this.proList1  = data.data.list;
      });
    },
    //获取列表
    _getAllUserInfo() {
      const info = {
        adminid: JSON.parse(window.localStorage.getItem("tltz_userinfo")).id,
        page: this.page,
        pageSize: this.pageSize,
        procuratorateid: this.procuratorateid,
        selecttype: this.selecttype,
        conditions: this.selectTxt,
        sexidwa: this.sexidwa,
        typeidwa: this.typeidwa,
      };
      getAllUserInfo(info).then(async (data) => {
        if (data.data == null) {
          this.page = 1;
          this.tableData = [];
          return;
        } else {
          this.tableData = data.data.list;
          this.total = data.data.total;
          this.currentPage = data.data.pageNum;
        }
      });
    },
    // //添加
    handleAdd() {
      this.dialogFormVisible11 = true;
      this.showdetail = false;
      this.form2 = {
        username: "",
        sex: "",
        phone: "",
        typeid: "",
        procuratorateid: "",
        deptid:'',
      }
        // console.log(JSON.parse(window.localStorage.getItem("tltz_userinfo").procuratorate.id),'JSON.parse(window.localStorage.getItem("tltz_userinfo").procuratorate.id')

        this.form2.procuratorateid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
        this.form.typeid =JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
        this.currentId = "";
      this.titleName = "新增用户信息";
      // }
    },
    //修改
    handleEdit(value) {
      let res = this.perList.filter((val) => val == 4);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        console.log(value, "vallue");
        this.dialogFormVisible = true;
        this.currentId = value;
        this.titleName = "编辑用户信息";
        getUserInfoById(value).then((data) => {
          data.data.procuratorateid = data.data.procuratorate.id;
          data.data.typeid = data.data.types.id;
          if (
            data.data.typeid == 2 ||
            data.data.typeid == 8 ||
            data.data.typeid == 9
          ) {
            data.data.deptid = data.data.department.id;
          }
          this.form = data.data;
          console.log(data.data);
          this.getdepList(data.data.procuratorateid);
        });
        this.showdetail = false;
      }
    },
    //详情
    handleDetail(value) {
      // let res = this.perList.filter((val) => val == 3);
      // console.log(res)
      // if(!res.length){
      //   Message.error('您没有这个权限');

      // }else{
      this.dialogFormVisible = true;
      this.currentId = value;
      this.titleName = "查看用户信息";
      getUserInfoById(value).then((data) => {
        data.data.procuratorateid = data.data.procuratorate.id;
        data.data.typeid = data.data.types.id;
        if (data.data.typeid == 2) {
          data.data.deptid = data.data.department.id;
        }
        this.form = data.data;
        this.getdepList(data.data.procuratorateid);
      });
      this.showdetail = true;
      // }
    },
    //点击确定
    handleClick() {
      const id = this.currentId;
      this.poster();
      console.log(this.form2, "formform");
      if (!id) {
        if (this.form2.username != "" &&this.form2.sex == 0 || 1 &&this.form2.phone != "" &&this.form2.typeid != "") {
          if (this.form2.typeid == 2 ||this.form2.typeid == 8 ||this.form2.typeid == 9) {
            console.log('进来等于2 8 9 11111111')
            if (this.form2.deptid != "") {
              console.log('进来等于2 8 9 22222')
              addUserInfo(this.form2).then(async (data) => {
                if (data.code !== 200) {
                  Message.error(data.msg);
                  return;
                }
                this.dialogFormVisible11 = false;
                Message.success("提交成功！");
                await this._getAllUserInfo();
              });
            }else{
              Message.error("请填写完整信息！");
              return
            }
          } else {
            console.log('进来不等于2 8 9')
            addUserInfo(this.form2).then(async (data) => {
              if (data.code !== 200) {
                Message.error(data.msg);
                return;
              }
              this.dialogFormVisible11 = false;
              Message.success("提交成功！");
              await this._getAllUserInfo();
            });
          }

          return;
        } else {
          Message.error("请填写完整信息！");
          return
        }
      } else {
        updateUserInfo(this.form).then(async (data) => {
          if (data.code !== 200) {
            Message.error(data.msg);
            return;
          }
          this.dialogFormVisible = false;
          Message.success("提交成功！");
          await this._getAllUserInfo();
        });
      }
    },
    handleStop(d, s) {
      let res = this.perList.filter((val) => val == 3);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        console.log(d, s);
        let is = s,
          id = d;
        if (is == 0) {
          is = 1;
        } else {
          is = 0;
        }
        this.$confirm("是否变更该用户当前状态？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            const params = {
              id,
              state: is,
            };
            updateUserInfo(params).then(async (data) => {
              if (data.code !== 200) return Message.error("变更状态失败！");
              await this._getAllUserInfo();
              Message.success("变更状态成功！");
            });
          })
          .catch(() => {
            Message.info("已取消!");
          });
      }
    },
    //删除
    handleRemove(id) {
      let res = this.perList.filter((val) => val == 5);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        this.$confirm("是否删除用户信息？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            deleteUserInfo(id).then(async (data) => {
              if (data.code !== 200) return Message.error("删除失败");
              console.log(data.code);
              await this._getAllUserInfo();
            });
            Message.success("删除成功！");
          })
          .catch(() => {
            Message.info("已取消删除!");
          });
      }
    },
    //搜索
    departSelect() {
      this._getAllUserInfo();
      // let name = this.selectTxt;
      // this.page = 1;
      // (name, this.page).then((data) => {
      //   console.log(data.data);
      //   if (data.data == null) {
      //     Message.warning("查询内容不存在！");
      //   } else {
      //     this.tableData = data.data.list;
      //     this.total = data.data.total;
      //     this.currentPage = data.data.pageNum;
      //   }
      // });
    },
    //导出
    handleExport() {
      let res = this.perList.filter((val) => val == 102);
      console.log(res);
      if (!res.length) {
        Message.error("您没有这个权限");
      } else {
        var adminid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).id
        var page = this.page
        var pageSize = this.pageSize
        var procuratorateid = this.procuratorateid
        var selecttype = this.selecttype
        var conditions = this.selectTxt
        var sexidwa = this.sexidwa
        var typeidwa = this.typeidwa
        downloadFile(BASE_URL + `/hring/ExportUserInfo??adminid=${adminid}&page=${page}&pageSize=${pageSize}&procuratorateid=${procuratorateid}&selecttype=${selecttype}&conditions=${conditions}&sexidwa=${sexidwa}&typeidwa=${typeidwa}`);
      }
      
    },
    //表单
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //分页器
    handleCurrentChange(val) {
      this.page = val;
      // if (this.selectTxt !== "") {
      //   this.departSelect();
      //   return;
      // }
      this._getAllUserInfo();
    },
    //判断权限
    poster() {
      if (this.checkHear == true) {
        this.form.authorityHering = "1";
      } else {
        this.form.authorityHering = "0";
      }
      if (this.checkUser == true) {
        this.form.authorityJian = "1";
      } else {
        this.form.authorityJian = "0";
      }
      if (this.checkExpert == true) {
        this.form.authorityZhuan = "1";
      } else {
        this.form.authorityZhuan = "0";
      }

      if (this.checkHear == true) {
        this.form2.authorityHering = "1";
      } else {
        this.form2.authorityHering = "0";
      }
      if (this.checkUser == true) {
        this.form2.authorityJian = "1";
      } else {
        this.form2.authorityJian = "0";
      }
      if (this.checkExpert == true) {
        this.form2.authorityZhuan = "1";
      } else {
        this.form2.authorityZhuan = "0";
      }
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    closeDialog11() {
      this.dialogFormVisible11 = false;
    },
  },
  computed: {},
};
</script>
<style lang='less' >
.policemen_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-dialog {
    margin: 300px auto !important;
    width: 30% !important;
    min-width: 570px;
  }

  .el-dialog__header {
    border-bottom: 1px solid #e9e6e6;
  }
  .el-dialog__footer {
    border-top: 1px solid #e9e6e6;
  }
  .dialog_title {
    > img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    > span {
      font-size: 13px;
      font-weight: 400;
      color: #2d8cf0;
    }
  }
  .form_label {
    > span {
      color: red;
      margin-right: 5px;
    }
  }
  .el-form-item__label {
    text-align: left;
    .form_label {
      font-size: 13px;
    }
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item .el-input__inner {
    width: 275px;
    height: 33px;
  }
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #044bfe;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
  }
  .style_button_del {
    background: #ff0012;
    margin-left: 13px;
  }
  .el-table td,
  .el-table th {
    text-align: center;
    padding: 8px 0;
  }
  .el-table {
    margin-top: 15px;
    .el-button {
      width: 80px;
      height: 36px;
      font-size: 14px;
      color: #ffffff;
    }
    .editColor {
      background: #3a8fea;
    }
  }
  .el-button--danger {
    background: #ff5200 !important;
  }
  .el-pagination {
    // position: absolute;
    // bottom: 34px;
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
.style_button_Sel {
  margin-left: 10px;
}
.el-table_1_column_5 .cell {
  display: flex;
  justify-content: center;
  p {
    margin-right: 10px;
  }
}
</style>